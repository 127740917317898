.detailsContainer {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  /* justify-content: center;   */
}

.col {
  /* margin: 15px; */
  /* height: auto; */
  padding: 20px 50px 0px 50px;
  width: 80%;
}


.item {
  margin-top: 0;
  padding-bottom: 5px;
  color: var(--ciem-color-primary);
}

.container {
  padding: 50px 50px;
}

.backFinder {
  padding-left: 50px;
  margin-bottom: 5px;
  /* width: 100px; */
}
 
.formButton {
  background: var(--ciem-color-secondary);
  /* background-color: #fff; */
  border: 0;
  font-size: .8rem;
  border-radius: 5px;
  color: #fff;
  padding: 10px 30px;
  margin-right: 10px;
  text-transform: uppercase;
  width: 120px;
}


.colorTexto * {     
  color: var(--ciem-color-primary) !important;    
}
 

.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;  
  /* color: var(--ciem-color-primary); */

  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}
