/* .portal {
  position: relative; 
  bottom: 0;
  right: 0; 
  width: 100vh; 
  height: 100vw;
} */
   
.surveyContainer {  
  /* overflow: hidden;   */
  position: fixed;    
  
  right: 50px; 
  bottom: 50px;
  color: #fff;

  z-index: 2; 
  height: 120px;  
  width: 350px;    
  border-radius: 30px 10px;

  /* background-color: #999; */
  background-color: var(--ciem-color-secondary);
  /* text-align: center;   */
  /* font-size: 1.1rem;    */
  
  /* box-shadow: 10px 10px 50px rgba(24,56,182,0.4);  */
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);  
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: 1s, transform 2s;
} 

.surveyContainer:hover {
  /* font-size: 0.9rem; */
  /* transition: margin-right 4s ease-in-out; */
  cursor: pointer;
  /* background-color: #D45224;  */

  background-color: var(--ciem-color-primary); 
  transition: background-color 1s ease-out;
  outline: 0;    
}

strong {
  color: var(--ciem-color-primary);
}

.iconSurvey {
  z-index: 3;
  /* background-color: #fff; */
  /* color: var(--ciem-color-primary);	 */
  /* top: -25px;	 */
	/* box-shadow: -5px 4px 8px rgba(24,56,182,0.4);   */

}

.separatorUpDown {
  padding-right: 50px;
}