


.login {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center; 
  justify-content: center;     
} 
.logo {
  width: 70px;
  padding-bottom: 20px;
}

.card {
  width: 500px;
  /* width: 50%; */
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  text-align: center;  
}


.formInputGuess {  
  width: 40%;
  padding: 0;
  border: none;  
  background: none;
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  /* margin: 0 0em 1em 1em;   */
}

.formInput {  
  width: 80%;
  padding: 0.5em;
  border: none;
  background: none;
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 0 0;
}

.formButton {
  background: var(--ciem-color-secondary);
  border: 0;
  width: 80%;
  text-transform: uppercase;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
  border-radius: 5px;
} 

.formButtonGuess:hover,
.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}  

.parrafo {
  color: var(--ciem-color-text);
}

.smallText {
  font-size: .8rem;
}

.smallBlack {
  font-size: .8rem;
  font-weight: 400;
  color: var(--ciem-color-primary);
}

.textFieldButton {
  text-align: center;
}

.textField {
  text-align: left;
}

.titleForm {
  color: var(--ciem-color-text);
  text-transform: uppercase;
  padding-bottom: 10px;
}

.subTitleForm {
  color: var(--ciem-color-secondary);  
  padding-bottom: 10px;
}

.titleAlert {
  color: var(--ciem-color-alert);
  font-size: 0.8rem;
}