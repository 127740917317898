 .searchContainer {
  display: flex;
  justify-content: center;
} 
 
.searchBox {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 50%;
  color:var(--ciem-color-text);
}

.containerFinder {
	position: relative;
	width: 271px;  
}
.formInput {  
  width: 50%;
  padding: 1em;
  border: none;
  background: none;
  outline: 0; 
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em;
}

.formButton {
  /* background: var(--ciem-color-secondary); */
  background-color: #fff;
  border: 0;
  padding: 5px 20px;
  text-transform: uppercase;  


} 

.colorImage {
  color: var(--ciem-color-secondary);
  
}

/* .formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;  
  

  transition: background-color 1s ease-out;  
  outline: 0;
  cursor: pointer;
}  
*/
