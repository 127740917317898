
.footer-content{
  display: flex;
  align-items: center;
  flex-direction: column;
}

 
.footer-social{
  display: flex;  
  gap: 100px; 
  padding: 10px 0px; 
}

.social-icon{
  color: #fff;  
}

.social-item a:hover i{
  color: var(--ciem-color-disabled-color);
}

.footer-bottom{
  padding-bottom: 20px;
  font-size: .8rem;
}

@media only screen and (max-width: 600px) {
  .footer-social {
    gap: 50px;
  }
}

