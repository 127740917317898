
.containerForm {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
}

 
.modalBackground {
  z-index: 3;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  background-color: rgba(200, 200, 200, 0.791);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  --transform: translateY(-100vh);
  --transition: transform .8s;   
}
 
.modalTransition {
  transition: opacity .8s;
}

.modalContainer {
  width: 500px;
  height: 600px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
  position: relative;
  transition: transform 1s;
  transform: translateY(0%); 

}

.topModal {
  padding-top: 20px;
}
.modalContainer .title {
  display: inline-block;
  text-align: center;
  background-color: #BDBDBD;
  /* margin-top: 10px; */
  font-size: .8rem;
}

.title {
  border-radius: 12px 12px 0 0;
  padding: 10px;
}


.modalContainer .body {
  /* flex: 90%; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding-top: 10px;
  font-size: 1rem;
  text-align: left;
}

span {
  color: var(--ciem-color-primary);
}

.estiloInfo {
  padding-left: 30px;
  font-size: 1rem;
  font-weight: bold;

}


.containerCode {
  display: flex;  
  justify-content: center;
  align-items: center;
}
.shareCode {
  width: 150px;
  padding-bottom: 0px;
}

button .share {
  border-radius: 5px;
}

.formInput {  
  width: 50%;
  /* padding: 0.5em; */
  padding-left: 10px;
  border: none;  
  background: none;  
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em 0;
}
 
.containerInput {
  padding-left: 15px;
}

p {
  padding: 5px 5px 15px 15px;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  border-radius: 5px;
}

.modalContainer .footer button {
  width: 150px;
  height: 30px;
  margin: 5px;
  border: none;  
  background-color: var(--ciem-color-secondary);
  color: white;
  border-radius: 0px;
  font-size: .8rem;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
}

.footer button:hover {
  background-color: var(--ciem-color-primary);
  cursor: pointer;
  transition: background-color 1s ease-out;
}