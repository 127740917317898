
.searchBox {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  padding-left: 50px;
  padding-top: 10px;
  width: 50%;
  color:var(--ciem-color-text);
}

.formButton {
  background: var(--ciem-color-secondary);
  /* background-color: #fff; */
  border: 0px;
  border-radius: 5px;
  font-size: .8rem;
  color: #fff;
  padding: 10px 30px;
  margin-right: 10px;
  text-transform: uppercase;
}

.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  /* color: var(--ciem-color-primary); */

  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}
