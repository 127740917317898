
.login {    
  display: flex;
  /* width: 500px; */
  height: 100%;
  align-items: center;      
  justify-content: center;
} 

.card {
  width: 40%;
  max-width: 450px;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  text-align: center;  
}


.logo {
  width: 70px;
  padding-bottom: 20px;
}
 

.formInput {  
  width: 50%;
  padding: 0.5em;
  border: none;
  background: none;
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em 0;
}

.formButton {
  background: var(--ciem-color-secondary);
  border: 0;
  width: 50%;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
} 

.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}  

.parrafo {
  color: var(--ciem-color-text);
}

.parrafoLink {
  padding-top: 10px;
  color: var(--ciem-color-text);
}

.titleForm {
  color: var(--ciem-color-text);
  text-transform: uppercase;
  padding-bottom: 20px;
}

.subTitleForm {
  color: var(--ciem-color-secondary); 
  font-size: .9rem; 
  padding-top: 5px;
}


.textField {
  text-align: center;
}

.titleAlert {
  color: var(--ciem-color-alert);
  font-size: 0.8rem;
}

.smallText {
  font-size: .8rem;
}

.smallBlack {
  font-size: .8rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--ciem-color-primary);
}

@media only screen and (max-width: 600px) {
  .card {
    width: 98%;
    padding: 10px;
  }
}