

.imageCard {
  padding: 10px 25% 20px 25%;
}

.image {
  /* border-radius: 10px; */
  background-color: #fff;
  width: 100%;
  ;
}