
.containerQuestion {
	display: flex;
	flex-direction: column; 	
}


.controlInput {
	width: 80%;
	padding: 0.5em;
	border: none;
	background: none;
	outline: 0;
	color: #000;
	font-size: 1rem;
	border-bottom: 1px solid var(--ciem-color-secondary);
	margin: 1em 0;
}

.containerOptions {
  display: flex; 	
	width: 100%;
	text-align: center;
}

.containerMessage {
  display: flex;  
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;  
}

.controlButton {
	background: var(--ciem-color-secondary);		
	padding: 10px; 
	width: 100%;
	border: none;
  border-radius: 5px;
  margin-right: 20px;
	margin-top: 1em;
  text-transform: uppercase;
	margin-bottom: 3em;	
}

.controlButton:disabled {
	background: var(--ciem-color-disabled);
	color: #fff;
	transition: background-color 1s ease-out;
	outline: 0;
	cursor: none;
}

.controlButton:hover {
	background: var(--ciem-color-primary);
	color: #fff;
	transition: background-color 1s ease-out;
	outline: 0;
	cursor: pointer;
}

.controlButtonT {
	background: var(--ciem-color-secondary);		
	padding: 10px 10%;
  border-radius: 5px;
  margin-right: 20px;
	margin-top: 1em;
  text-transform: uppercase;
	margin-bottom: 3em;
}

.controlButtonT:hover {
	background: var(--ciem-color-primary);
	color: #fff;
	transition: background-color 1s ease-out;
	outline: 0;
	cursor: pointer;
}

.controlBtn {
	background: var(--ciem-color-secondary);		
	padding: 10px 5%;
	max-width: 200px;
  border-radius: 5px;
  margin-right: 20px;
	margin-top: 1em;
  text-transform: uppercase;
	margin-bottom: 3em;
}

.controlBtn:hover {
	background: var(--ciem-color-primary);
	color: #fff;
	transition: background-color 1s ease-out;
	outline: 0;
	cursor: pointer;
}

.mensajeFinal {	
	padding: 5px 5%;
  border-radius: 5px;
  margin-right: 20px;
	margin-top: 1em;  
	width: 90%;
	max-width: 500px;
	margin-bottom: 3em;
	margin: 2px;
	border-color: var(--ciem-color-secondary);
	resize: none;
}

.mensajeFinal:hover, 
textarea:focus {			
	outline: none !important;	
	border-color: var(--ciem-color-primary);	
}
 
.titleQuestion {
	color: var(--ciem-color-text);
	/* text-transform: uppercase; */
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 20px;
}

.subTitleQuestion {
	color: var(--ciem-color-secondary);
  text-align: center;
  font-size: 0.8rem;
}


/**
	FORM CONTACT
*/

.inputText {  
  width: 100%;
  padding: 0.5em;
  border: none;
  background: none;
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em 0;
	border-radius: 0;
}

.inputText:hover {  
  /* color: #fff;	 */
  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}  

/**	--------------------------------------------------------------------------
	styles CHECKBOXES
*/

.containerCheck {
  display: block;
  position: relative; 

  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute; 
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ddd;
}

/* On mouse-over, add a grey background color */
.containerCheck:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheck input:checked ~ .checkmark {
  background-color: var(--ciem-color-secondary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.containerCheck .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/**
	--------------	END STYLE CHECKBOXES
*/








 @media screen and  (max-width: 768px) {
	 .containerOptions {
		 flex-direction: column;
		 padding: 0 5px;
	 }

	 .containerMessage {
		 flex-direction: column;
	 }
 }