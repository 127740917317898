.expedienteGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px); 
  gap: 100px;
  padding: 50px; 
  justify-content: center;
}

@media (max-width: 560px) {
  .expedienteGrid {    
    grid-template-columns: 100%; 
  }
}
