@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;700&display=swap');

:root {
  --ciem-color-primary: #11847d;
  --ciem-color-secondary: #48c1ba;
  --ciem-color-disabled: #777;
  --ciem-color-alert: red;
  --ciem-color-warning: orange;
  --ciem-color-sucess: skyblue;
  --ciem-color-disabled-border-color: rgba(118, 118, 118, 0.3);
  --ciem-color-disabled-color: rgba(16, 16, 16, 0.3);
  --ciem-color-text:  rgb(66, 66, 66);
  --ciem-color-disabled-background-color: rgba(239, 239, 239, 0.3); 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* color: #fff; */
}

.navbar-content {
  grid-area: navbar-content;  
  background-color: var(--ciem-color-primary);  
  position: sticky;
  top: 0; 
}

.main-content {
  grid-area: main-content;
  background-color: #f5f5f5;
  /* overflow: auto; */
  overflow-y: scroll;
  color: var(--ciem-color-text);
}

.footer-content {
  grid-area: footer-content;
  background-color: var(--ciem-color-secondary);
}

.grid-container {
  /* position: fixed; */
  width: 100vw;
  
  height: 100vh;
  display: grid;
  grid-template:
    "navbar-content"  auto
    "main-content"    1fr
    "footer-content"  auto  
}

.nav{
  display: flex;
  justify-content: space-between;
}

.navbar-menu {
  display: flex;
  line-height: 50px;
}

nav a {
  text-decoration: none;
  /* display: inline-block; */
  padding: 1rem;
}

nav a:hover{
  background-color: var(--ciem-color-secondary);
  color: #fff;
}


.active {
  background-color: var(--ciem-color-secondary);
}


