@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;700&display=swap');

:root {
  --ciem-color-primary: #11847d;
  --ciem-color-secondary: #48c1ba;
  --ciem-color-disabled: #777;
  --ciem-color-alert: red;
  --ciem-color-warning: orange;
  --ciem-color-sucess: skyblue;
  --ciem-color-disabled-border-color: rgba(118, 118, 118, 0.3);
  --ciem-color-disabled-color: rgba(16, 16, 16, 0.3);
  --ciem-color-text:  rgb(66, 66, 66);
  --ciem-color-disabled-background-color: rgba(239, 239, 239, 0.3); 
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
}


.header {
  grid-area: header;    
  background-color: #f5f5f5;
  display: flex;    
  align-items: center;
  justify-content: center; 
}

.main {
  grid-area: main;
  background-color: #f5f5f5;
  color: var(--ciem-color-text);

  display: flex;
  align-items: center;
  justify-content: center; 

}


.container {
  height: 90vh;
  display: grid;
  grid-template:
    "header"  .8fr
    "main"    5fr

}

.logo {
  padding: 10px;
}

p {
  color: #f5f5f5;
  font-size: 1.2rem;
  padding: 5px;
}