 
 
.expedienteCard {
  list-style: none;
  font-size: 1.5rem;
  /* text-align: center; */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 10px 30px;
}

.expedienteCard:hover {
  opacity: 0.8;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
} 

.expedienteBottom {
  padding: 2px 16px;
}

.parrafoLink {  
  padding-top: 10px;
  color: var(--ciem-color-text);
}

.smallText {
  font-size: .8rem;
}


.title {
  font-size: .8rem;
  padding: 10px 0px 0px 10px;
  margin-bottom: 5px;
  color: var(--ciem-color-primary); 
}

.smallBlack {
  font-size: .8rem;
  padding: 0px 0px 10px 10px;   
  color: var(--ciem-color-primary); 
}
.smallCapitalize {
  text-transform: capitalize !important; 
}

/* se elimina la link de los textos */
.none {
  text-decoration: none;
}
.smallSubTitle {
  padding: 0px 0px 10px 10px;  
  color: #000;
  font-size: .8rem;
}


@media (max-width: 560px) {
  .expedienteImage {
    width: 90%;
    height: auto;
  }
}



 
